import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"

import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"

const BrandingLogoPage = ({ data }) => {
  const cardSettings = {
    gap: "3rem",
    gapMd: "2rem",
    borderRadius: "0.5rem",
    background: "white",
    padding: "2rem",
  }

  const images = processImages(data.images.edges)

  return (
    <Layout
      title={`Klipfolio Logo`}
      description={`Guidelines & best practices for using Klipfolio's logo.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex gap="6rem" gapSm="4rem">
          <Flex>
            <Container>
              <Heading
                fontSize="3rem"
                as="h1"
                padding="12rem 0 0"
                paddingSm="8rem 0 0"
              >
                Klipfolio Logo Brand Guidelines
              </Heading>
            </Container>
          </Flex>
          <Flex background="#f3f5fc" padding="6rem 0" paddingSm="2rem 0">
            <Container>
              <Grid gap="2rem" columns="repeat(2, 1fr)" columnsMd="1fr">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  {...cardSettings}
                >
                  <Image
                    height={200}
                    width={200}
                    objectFit="scale-down"
                    file={images["efbdf06d-c41c-4261-b50a-0dad5d0cfbf7"]}
                  />
                </Flex>
                <Flex {...cardSettings}>
                  <Heading as="h4" mobileScale={0.8} tabletScale={0.9}>
                    Our logo.
                  </Heading>
                  <Paragraph>
                    It’s well balanced and timeless.The font was uniquely
                    crafted by expert typographers. They designed the corners
                    and edges to have a soft feel and selected black and red
                    colors to evoke a sense of urgency and action.
                  </Paragraph>
                  <Anchor
                    link="https://website-assets.klipfolio.com/Klipfolio-Logo.zip"
                    margin="auto 0 0"
                    arrow
                  >
                    Download Logo Package
                  </Anchor>
                </Flex>
              </Grid>
            </Container>
          </Flex>
          <Container>
            <Flex gap="8rem" gapSm="6rem" margin="0 0 6rem">
              <div>
                <Heading fontSize="3rem" as="h3" margin="0 0 4rem">
                  Logo Colours
                </Heading>
                <Grid gap="2rem" columns="repeat(3, 1fr)" columnsMd="1fr">
                  <Flex gap="2rem">
                    <Div
                      background="black"
                      padding="1rem 2rem"
                      borderRadius="1rem"
                    >
                      <Paragraph color="white">Logo Black</Paragraph>
                    </Div>
                    <Flex gap="0.5rem">
                      <Paragraph>
                        <b>HEX:</b> #000000
                      </Paragraph>
                      <Paragraph>
                        <b>CMYK:</b> C0 M0 Y0 K100
                      </Paragraph>
                      <Paragraph>
                        <b>PMS Coated:</b> Black 6C
                      </Paragraph>
                    </Flex>
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      background="#ed1c24"
                      padding="1rem 2rem"
                      borderRadius="1rem"
                    >
                      <Paragraph color="white">Logo Red</Paragraph>
                    </Div>
                    <Flex gap="0.5rem">
                      <Paragraph>
                        <b>HEX:</b> #ed1c24
                      </Paragraph>
                      <Paragraph>
                        <b>CMYK:</b> C0 M94 Y100 K0
                      </Paragraph>
                      <Paragraph>
                        <b>PMS Coated:</b> 1795
                      </Paragraph>
                    </Flex>
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      border="1px solid #eee"
                      padding="1rem 2rem"
                      borderRadius="1rem"
                    >
                      <Paragraph>Logo White</Paragraph>
                    </Div>
                    <Flex gap="0.5rem">
                      <Paragraph>
                        <b>HEX:</b> #ffffff
                      </Paragraph>
                      <Paragraph>
                        <b>CMYK:</b> C0 M0 Y0 K0
                      </Paragraph>
                      <Paragraph>
                        <b>PMS:</b> None
                      </Paragraph>
                    </Flex>
                  </Flex>
                </Grid>
              </div>
              <div>
                <Heading fontSize="3rem" as="h3" margin="0 0 4rem">
                  Klipfolio Logo Exclusion Zone
                </Heading>
                <Grid gap="2rem" columns="repeat(2, 1fr)" columnsSm="1fr">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid #eee"
                    {...cardSettings}
                  >
                    <Image
                      height={100}
                      width={200}
                      objectFit="scale-down"
                      file={images["a5be4dbf-5595-43c4-849e-72a44ab6834a"]}
                    />
                  </Flex>
                  <Flex {...cardSettings} justifyContent="center">
                    <Paragraph>
                      Use the triangle to determine the exclusion zone.
                    </Paragraph>
                  </Flex>
                </Grid>
              </div>
              <div>
                <Heading fontSize="3rem" as="h3" margin="0 0 4rem">
                  Klipfolio Badge Application
                </Heading>
                <Grid gap="2rem" columns="1fr 1fr" columnsSm="1fr">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid #eee"
                    padding="4rem 0"
                    borderRadius="1rem"
                  >
                    <Image
                      height={100}
                      width={200}
                      objectFit="scale-down"
                      file={images["efbdf06d-c41c-4261-b50a-0dad5d0cfbf7"]}
                    />
                  </Flex>
                  <Flex gap="1rem" alignSelf="center">
                    <Heading as="h4" mobileScale={0.8} tabletScale={0.9}>
                      Logo (Black & Red)
                    </Heading>
                    <Paragraph>
                      Our iconic logo can be used in print and on the web.
                      Remember to always include the ®. It was designed to be
                      used on a white background only, so please avoid placing
                      it on a colored background or over top of an image.
                    </Paragraph>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    background="black"
                    padding="4rem 0"
                    borderRadius="1rem"
                  >
                    <Image
                      height={100}
                      width={200}
                      objectFit="scale-down"
                      file={images["0a5d7794-67e3-4dde-8fc3-442faf4c86cc"]}
                    />
                  </Flex>
                  <Flex gap="1rem" alignSelf="center">
                    <Heading as="h4" mobileScale={0.8} tabletScale={0.9}>
                      Logo (White & Red)
                    </Heading>
                    <Paragraph>
                      Our white logo with the red triangle can be used on dark
                      backgrounds and is acceptable for web use. Always include
                      the ® and never place it over top of an image.
                    </Paragraph>
                  </Flex>
                  <Flex
                    border="1px solid #eee"
                    padding="4rem 0"
                    borderRadius="1rem"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      height={100}
                      width={200}
                      objectFit="scale-down"
                      file={images["a30e49bc-532a-4c72-96a2-4d97c5e5afa7"]}
                    />
                  </Flex>
                  <Flex gap="1rem" alignSelf="center">
                    <Heading as="h4" mobileScale={0.8} tabletScale={0.9}>
                      Logo (Black)
                    </Heading>
                    <Paragraph>
                      Our all-black logo is more of a traditionalist and should
                      be used for printing on black and white only.
                    </Paragraph>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    background="black"
                    padding="4rem 0"
                    borderRadius="1rem"
                  >
                    <Image
                      height={100}
                      width={200}
                      objectFit="scale-down"
                      file={images["511f8057-9a39-4442-8178-ccf2944bd5c6"]}
                    />
                  </Flex>
                  <Flex gap="1rem" alignSelf="center">
                    <Heading as="h4" mobileScale={0.8} tabletScale={0.9}>
                      Logo (White)
                    </Heading>
                    <Paragraph>
                      Our all-white logo is to be used on dark backgrounds. It
                      makes for a great watermark as well, so use this when it’s
                      time to leave an impression.
                    </Paragraph>
                  </Flex>
                </Grid>
              </div>
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Layout>
  )
}

BrandingLogoPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BrandingLogoPage

export const pageQuery = graphql`
  query BrandingLogoQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "efbdf06d-c41c-4261-b50a-0dad5d0cfbf7"
            "a5be4dbf-5595-43c4-849e-72a44ab6834a"
            "a30e49bc-532a-4c72-96a2-4d97c5e5afa7"
            "511f8057-9a39-4442-8178-ccf2944bd5c6"
            "0a5d7794-67e3-4dde-8fc3-442faf4c86cc"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
